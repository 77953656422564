// src/components/Header.js
import React from 'react';

function Header() {
    return (
        <header>
            <h1>Vrip</h1>
            <p>Bienvenido a la página de información de mi aplicación</p>
        </header>
    );
}

export default Header;
