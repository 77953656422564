// src/components/About.js
import React from 'react';

function About() {
    return (
        <section>
            <h2>Acerca de</h2>
            <p>Esta es una aplicación increíble que hace muchas cosas asombrosas.</p>
        </section>
    );
}

export default About;
