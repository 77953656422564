// src/components/Features.js
import React from 'react';

function Features() {
    return (
        <section>
            <h2>Características</h2>
            <ul>
                <li>Característica 1</li>
                <li>Característica 2</li>
                <li>Característica 3</li>
            </ul>
        </section>
    );
}

export default Features;
